<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
		<div class="w-100 h-auto flex flex-column">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
				<el-form-item label="标题" prop="title">
				  <el-input v-model="form.title" placeholder="请输入标题" type="text" clearable />
				</el-form-item>
				<el-form-item label="作者" prop="anthor">
				  <el-input v-model="form.anthor" placeholder="请输入作者" type="text" clearable />
				</el-form-item>
                <el-form-item label="PDF" prop="pdf">
					<div class="position-relative flex align-start" style="width: 250px;" v-if="form.pdf">
						{{form.file_name || ''}}
						<i class="el-icon-delete text-white position-absolute p-1" style="top: 0;right: 0;background-color: rgba(0,0,0,0.4);cursor: pointer;" @click.stop="delVideo()"></i>
					</div>
                	<el-upload
                	  class=""
                	  ref="upload"
                	  :limit="1"
					  action="/manage/upload/pdf"
                	  :on-success="uploadSuccess"
                	  :before-upload="beforeUpload"
					  :show-file-list='false'
                	  accept=".pdf"
					  v-show="!form.pdf"
                	  >
                	  <div class="border rounded flex-all mr-3" style="width: 100px;height: 100px;line-height: 100px;cursor: pointer;">
                		  <i class="el-icon-plus text-muted" style="font-size: 30px;"></i>
                	  </div>
                	</el-upload>
                </el-form-item>
				<el-form-item  label="发布状态">
				   <el-radio-group v-model="form.is_pub">
					<el-radio :label="1">使用</el-radio>
					<el-radio :label="2">停用</el-radio>
				   </el-radio-group>
				 </el-form-item>
				<el-form-item label="排序" prop="sort">
				  <el-input v-model="form.sort" placeholder="请输入排序" type="text" clearable />
				</el-form-item>
			</el-form>
			<div class="w-100 flex-all mt-2">
			  <el-button size="medium" @click="cancel">取 消</el-button>
			  <el-button type="primary" size="medium" @click="handleSubmit" style="margin-left: 30px;">确 定</el-button>
			</div>
		</div>
		</el-card>
	</div>
</template>

<script>
import GlobalTips from "@/components/GlobalTips";
import common from "@/common/mixins/common.js"
import Breadcrumb from '@/components/Breadcrumb';
export default {
	inject:['app'],
	mixins:[common],
	components: {
		GlobalTips,
		Breadcrumb,
	},
	data() {
		return {
			preUrl: 'article',
			autoRequest: false,
			form:{
				title:'',
				anthor:'安徽省电力协会',
				type_id:13,
				cover:'',
				url:'',
				content:'',
				sort:100,
				is_pub:1,
				video:'',
				pdf:'',
				size:'',
				file_name:'',
			},
			rules:{
				title: [
				  { required: true, message: '请输入文章标题', trigger: 'blur' }
				],
				pdf: [
				  { required: true, message: '请选择PDF', trigger: 'blur' }
				],
			},
		}
	},
	mounted() {
		if(this.$route.query.article){
			 this.form = this.$route.query.article
			 this.form.file_name = this.$route.query.article.title
		}
	},
	methods:{
		handleSubmit() {
		  this.$refs.form.validate(valid => {
		    if (valid) {
		      if (this.form.sort) {
		        this.form.sort = parseInt(this.form.sort)
		      }
			  this.form.images = this.images
		      if (this.form.id === undefined) {
		        this.axios.post('/manage/'+this.preUrl + '/add', this.form).then(res => {
		          if (res.status) {
		            this.$message.success('编辑成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      } else {
		        this.axios.put('/manage/'+this.preUrl + '/edit/' + this.form.id, this.form).then(res => {
		          if (res.status) {
		            this.$message.success('修改成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      }
		    }
		  })
		},
		cancel(){
			this.$router.go(-1);
		},
		delVideo(){
			this.form.pdf = ''
			this.form.file_name = ''
			if(this.fileList.length > 0){
				this.fileList = []
				this.$refs.upload.clearFiles()
			}
		},
		beforeUpload(file){
			let fileArr = file.name.split('.')
			let suffix = fileArr[fileArr.length - 1]
			//只能导入.xls和.xlsx文件
			if (!/(pdf)/i.test(suffix)) {
			this.$message('文件格式不正确')
			return false
			}
			return true
		},
		uploadSuccess(e){
			console.log(e)
			 if(e.status){
			   this.form.pdf = e.data.url
			   this.form.file_name = e.data.file_name
			   this.form.size = e.data.size
			 }
			 this.$refs.upload.clearFiles()
		},
		uploadError(e){
			console.log(e)
			this.$refs.upload.clearFiles()
		},
	},
}
</script>

<style>
</style>